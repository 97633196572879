/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 //import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
 import {IntlProvider} from 'react-intl';
 //import 'intl';
 import {
   getLangs,
   getUrlForLang,
   getCurrentLangKey,
   isHomePage
 } from 'ptz-i18n';

 function SEO({ description, key, lang, langNo, meta, title, robots, location, slogan }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             keywords
             languages{
                langs
                defaultLangKey
             }
           }
         }
       }
     `
   )

   const metaDescription = description || site.siteMetadata.description[langNo]
   const url = location;
   const { langs, defaultLangKey } = site.siteMetadata.languages;
   const langKey = getCurrentLangKey(langs, defaultLangKey, url);
   const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
   const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
   return (
     <IntlProvider
      locale={langKey}
      >
     <Helmet
       htmlAttributes={{
         langKey
       }}
       defaultTitle={`${site.siteMetadata.title[langNo]}`}
       titleTemplate={`%s | ${site.siteMetadata.title[langNo]}`}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           name: `keywords`,
           content: site.siteMetadata.keywords[langNo],
         },
         {
           name: `robots`,
           content: robots,
         },
         {
           property: `og:title`,
           content: site.siteMetadata.title[langNo],
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:creator`,
           content: site.siteMetadata.author,
         },
         {
           name: `twitter:title`,
           content: site.siteMetadata.title[langNo],
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
       ].concat(meta)}
     />
     </IntlProvider>
   )
 }

 SEO.defaultProps = {
   lang: `pl`,
   meta: [],
   langNo: 0,
   description: ``,
   key:``,
   robots:  `noindex, nofollow`,
   location: ``
 }

 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   robots: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   location: PropTypes.string,
   langNo: PropTypes.number,
   title: PropTypes.string.isRequired
 }

 export default SEO
