import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';
import Nav from 'react-bootstrap/Nav'
import { StaticQuery, graphql} from "gatsby";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

export class Menu extends Component {
  static propTypes = {
    rowClass: PropTypes.string,
    colClass: PropTypes.string,
    contClass: PropTypes.string,
    menuItems: PropTypes.object,
    noCol: PropTypes.string,
    logoCol: PropTypes.object,
    navbarOff: PropTypes.string,
    selLang: PropTypes.string
  }

  render() {
    const cMenu = this.props.confMenu;
    const langNo = this.props.selLang;
    return (
        <Container id="menuComponent" className={this.props.contClass} fluid>
          <Row noGutters className={this.props.rowClass}>
            <Nav className={this.props.navClass}>
              <Scrollspy
                items={this.props.menuItems}
                currentClassName="active"
                offset={this.props.navbarOff - 10}
              >
              {
                cMenu.edges[0].node.linkData.map((itemsData,i)=>(

                  <Col className={this.props.noCol===i?('col-md-4 menu-logocolumn menu-col'):(this.props.colClass+' hvr-underline-from-center ')} id={(`field${i}`+"-"+langNo)}>
                    <Nav.Item as="li" className="">
                    {
                      this.props.noCol===i?(
                        <Scroll type="id" element={itemsData.dataHref[0]}>
                        {this.props.logoCol}
                        </Scroll>
                      ):(
                        <Scroll type="id" offset={this.props.navbarOff} element={itemsData.dataHref[0]}>
                            <Nav.Link className="menu" href={`#${itemsData.dataHref[0]}`}>{itemsData.dataHref[langNo]}</Nav.Link>
                        </Scroll>
                    )
                    }
                    </Nav.Item>
                  </Col>

                  ))
              }
              </Scrollspy>
            </Nav>
          </Row>
        </Container>
    );
  }
}

export default props =>(
  <StaticQuery
    query={graphql`
      query  {
        allJson(limit: 1, sort: {fields: linkData}) {
          edges {
            node {
              linkData {
                dataHref
              }
            }
          }
        }
      }
    `}
    render = {({ allJson}) => ( <Menu confMenu={allJson} {...props}/>)}
/>
);
