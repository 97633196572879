import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from "gatsby";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  top: 0;
  left:0;
  padding:20px;
  background-color:transparent;
  position:fixed;
  display:none;
`;
const Absolute = styled.div`
  background-color:rgba(255,255,255,.9);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index:1234 !important;
  width:100%;
`;
const Panel = styled.div`
  position: relative;
  height: 96px;
  width: 100%;
  padding:24px;
  display: block;
  background-color:transparent;
  color:black;

`;
const Screen = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  width: 100%;
  display: block;
  background-color:transparent;
  color:black;
  text-align:center;
  padding: 0 1rem;
  padding-bottom: 5rem;
`;
const Header = styled.h2`
  width:100%;
  text-align:center;
`;
const ParaHeader = styled.strong`
  whidth:100%;
  text-align: center;
  color: black;
`;
const ParaCont = styled.p`
  whidth:100%;
  text-align: left;
  color: black;
`;
const Clickable = styled.p`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width:auto;
  height: auto;
  display: inline;
  margin: 0;
  padding: 0;
  border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
  &:hover{
    border: none;
  }
  `;

class InfoDiv extends Component {

  static propTypes = {
    element: PropTypes.string,
    pp_info: PropTypes.string,
    lang: PropTypes.number,
    children: PropTypes.node.isRequired
  };

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e){
    e.preventDefault();
    if (document.getElementById("con").style.display==="") {

      document.getElementById("con").style.display="block";

    }else {

      document.getElementById("con").style.display="";
    }

  }

  render() {
    const content = this.props.dataContent;
    return (
      <font>
      <Container id="con">
      <Absolute>
        <Panel><span onClick={this.handleClick} style={{cursor: `pointer`}}>X</span></Panel>
        <Screen>
								<Header><div dangerouslySetInnerHTML={{ __html:content.edges[0].node.title[this.props.lang] }}></div></Header>
                {content.edges[0].node.paragaph.map(innerItems=>(
                  <span>
                  <ParaHeader>
                    <div dangerouslySetInnerHTML={{ __html:innerItems.head[this.props.lang]}}>
                    </div>
                  </ParaHeader>
                  <ParaCont>
                    <div dangerouslySetInnerHTML={{ __html:innerItems.content[this.props.lang]}}>
                    </div>
                  </ParaCont>
                  </span>
                )
              )}
      </Screen>
      </Absolute>
      </Container>
      <Clickable onClick={this.handleClick}>
        {this.props.pp_info}
      </Clickable>

      </font>
    );
  }
 }

 export default props =>(
   <StaticQuery
     query={graphql`
       query myData {
         allJson(sort: {fields: title}, limit: 1){
           edges {
             node {
               title
               paragaph{
                 head
                 content
               }
             }
           }
         }
   }
     `}
     render = {({ allJson }) => <InfoDiv dataContent={allJson} {...props}/>}
       />
 );
InfoDiv.defaultProps = {
    pp_info: "Polityka Prywatności",
    lang: 0
}
