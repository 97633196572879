import React from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

class EngineGallery extends React.Component {

  static propTypes = {
    mainGrid: PropTypes.object,
    gridItem: PropTypes.object,
    contClass: PropTypes.string,
    hrefClass: PropTypes.string,
    paraClass: PropTypes.string,
    noCol: PropTypes.string,
    logoCol: PropTypes.object
  }

  constructor() {
    super();
    this.state = {
      currentImage :0,
      currentImageArray: '',
      viewerIsOpen: false,
      setViewerIsOpen: false
    }

  }
  openLightbox = (e, {index}) => {
    let currImage = index;
    let currentImageArray =index+'photos';

    this.setState({
        currentImage : currImage,
        currentImageArray: currentImageArray,
        viewerIsOpen: true,
        setViewerIsOpen: false
      })

  }

  closeLightbox = () =>{
    this.setState({
        currentImage : 0,
        currentImageArray: '',
        viewerIsOpen: false,
        setViewerIsOpen: false
      })
  }

  render() {

    const photo = this.props.gridItem
    const photos = this.props.mainGrid
    const viewerIsOpen = this.state.viewerIsOpen;
    return (

      <div>
        <Gallery photos={photos} onClick={this.openLightbox}/>

        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={this.closeLightbox}>

            <Carousel
              currentIndex={0}
              views={
                photo[this.state.currentImage][0].map(x => ({
                src: x.src,
                caption: x.alt
                }))
              }
            />
          </Modal>
        ) : null}
        </ModalGateway>
      </div>
    );
  }

}

export default EngineGallery;
