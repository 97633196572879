import React from "react"
import PropTypes from "prop-types"
import Sidebar from "../components/sidebar"
import SEO from "../components/seo"
import Footer from "../components/footer"
import "../scss/style.scss"

const Layout = ({ children, seoTitle, sName, sSlogan, language, href}) => {
  return (
    <>
      <SEO location={href} slogan={sSlogan} langNo={language} robots="index, follow" />
      <div
           key={`loader`}
           id="___loader"
           className="starter"
        >
        </div>

      <Sidebar siteName={sName} siteSlogan={sSlogan} lang={language}/>
        {children}
      <Footer siteName={seoTitle} />
    </>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sSlogan: PropTypes.string,
  href: PropTypes.string,
  language: PropTypes.string.isRequired
}

export default Layout
