import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby";
import Menu from "./menu"
import InfoBar from "./InfoBar"
import InfoBar2 from "./InfoBar2"
import Scroll from './Scroll';
import LogoPl from "../svg/logoPl.svg";
import LogoEn from "../svg/logoEn.svg";
import LogoDe from "../svg/logoDe.svg";
import LogoRu from "../svg/logoRu.svg";


export class Sidebar extends Component {
  static propTypes ={
    siteName: PropTypes.string,
    siteSlogan: PropTypes.string,
    logoSvg: PropTypes.string,
    lang: PropTypes.number
  }
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      active: false,
      navBarActiveClass: '',
      telHidden:' ',
      navExpanded: false
    };
  }
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(){

  }

  render() {
    const menuItems = ["intro","one","gallery","","b2b","candel","contact"]
    const siteNameH1 = ["<h1>Kwiaciarnia Alma Wrocław</h1>","<h1>Alma Florist's Wroclaw</h1>","<h1>Alma Blumenladen Breslau</h1>","<h1>Цветочный магазин Алма Вроцлав</h1>"]
    const LogoCol=(
        <a href="#intro" data-rb-event-key="#intro" className="menu"><h1>{this.props.siteName}</h1></a>
    )

    return (
    <section id="sidebar" className={`${this.state.navBarActiveClass}`}>

      <Navbar fixed="top" expand="md" className="mobile" collapseOnSelect={true}>
      <Container fluid>
        <Row  className="full-width justify-content-center" noGutters>
          <Col xs={12} className="mobile-logo">
            {
              this.props.lang===0?<LogoPl />:""
            }

            {
              this.props.lang===1?<LogoEn />:""
            }
            {
              this.props.lang===2?<LogoDe />:""
            }
            {
              this.props.lang===3?<LogoRu />:""
            }

          </Col>
            <Scroll type="id" element="intro">
          <Col xs={10}></Col>
          </Scroll>
          <Col xs={2} className="icon">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" id="hamburger" className="animated-icon1"/>
          </Col>
        </Row>
      </Container>

      <Navbar.Collapse id="responsive-navbar-nav">
        <Menu
          menuItems = {menuItems}
          navClass=" menu"
          colClass="col-md-2 menu-col"
          contClass="menu-container"
          selLang = {this.props.lang+1}
          navbarOff = {-60}
        />
        <InfoBar
          contClass="Bar-cont"
          rowClass="Bar-row"
          colClass="Bar-col"
          iconClass="Bar-icon"
          nameH1 = {siteNameH1[this.props.lang]}
          lang={this.props.lang + 3}
        />
      </Navbar.Collapse>

      </Navbar>
      <Navbar fixed="top" className="big">
        <span id="scrollElem">

          <InfoBar
            contClass="Bar-cont"
            rowClass="Bar-row"
            colClass="Bar-col"
            iconClass="Bar-icon"
            lang={this.props.lang + 3}
            nameH1=""
          />

          <Menu
            menuItems = {menuItems}
            navClass="menu"
            colClass="col-md menu-col"
            contClass="menu-container"
            noCol={3}
            logoCol={LogoCol}
            navbarOff = {-106}
            selLang = {this.props.lang+1}
          />


        </span>
      </Navbar>
      <Container fluid className="static ">
        <Row noGutters>
          <Col className="d-md-block d-none">

          <InfoBar2
          contClass="Bar2-contstatic"
          rowClass="Bar2-row"
          colClass="Bar2-col col-md-2"
          noCol={2}
          logoCol={<><div className="spacer">&nbsp;</div></>}
          />

          <InfoBar2
          contClass="Bar2-contstatic"
          rowClass="Bar2-row"
          colClass="Bar2-col col-md-2"
          noCol={2}
          logoCol={<h3>{this.props.siteSlogan}</h3>}
          />

          </Col>
        </Row>
      </Container>
    </section>
    );
  }
}

export default props =>(
  <StaticQuery
    query={graphql`
      query sideConf {
        allJson(limit: 1, sort: {fields: linkData}) {
          edges {
            node {
              linkData {
                dataHref
              }
            }
          }
        }
      }
    `}
    render = {({ allJson }) => <Sidebar confSidebar={allJson} {...props}/>}
      />
);
