import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'


const Footer = ({siteName}) => (
  <footer>
    <Container fluid>

      <Row noGutters={true}>
      <Col xs={12} className="infoBar">
      © {new Date().getFullYear()}, {siteName}<span className="float-right">Projekt i realizacja: Czechowski-Projekty</span>
      </Col>
      </Row>
    </Container>
  </footer>
)


export default Footer
