import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export class InfoBar2 extends Component {

  static propTypes = {
    rowClass: PropTypes.string,
    colClass: PropTypes.string,
    contClass: PropTypes.string,
    noCol: PropTypes.string,
    logoCol: PropTypes.string,
  };

    render() {

    const content = this.props.dataContent.edges[0].node.infoBar

    return (

      <Container className={this.props.contClass} fluid>
        <Row className={this.props.rowClass} noGutters>
        {
          content.map((info, i)=>(
            <Col className={this.props.noCol===i?('col-md-4 Bar2-logocolumn'):(this.props.colClass)} id={`field${i}`}>
            {
              this.props.noCol===i?(
                <>
                {this.props.logoCol}
                </>
              ):("")
            }
            </Col>
          ))
        }
        </Row>
      </Container>
    );
  }
 }

 export default props =>(
   <StaticQuery
     query={graphql`
       query {
         allJson(sort: {fields: infoBar}, limit: 1) {
           edges {
             node {
               infoBar {
                 class
                 col {
                   item
                 }
               }
             }
           }
         }
       }
     `}
     render = {({ allJson }) => <InfoBar2 dataContent={allJson} {...props}/>}
       />
 );
