import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export class InfoBar extends Component {
  constructor(props){
    super(props)
    library.add(fab, fas)
  }

  static propTypes = {
    rowClass: PropTypes.string,
    colClass: PropTypes.string,
    contClass: PropTypes.string,
    hrefClass: PropTypes.string,
    paraClass: PropTypes.string,
    iconClass: PropTypes.string,
    dataContent: PropTypes.object,
    nameH1: PropTypes.string,
    lang: PropTypes.string
  };
    render() {

      const Data = ({colEnd,href,icon,text,hClass,iClass,pClass,nameH1,iter})=>(
        text!==""?(
          href!==""?(
            <a className={hClass} href={href}>
            {
              icon[0]===""||icon[1]===""?<div className={icon[0]===""&&icon[1]!==""?icon[1]+" link":"link"} dangerouslySetInnerHTML={{ __html:text}}/>:<FontAwesomeIcon className={iClass} icon={icon}/>
            }
            </a>
          )
          :(
            <p className={pClass}>
            {
              icon[0]===""||icon[1]===""?((nameH1!=="")&&(iter===2)?<div dangerouslySetInnerHTML={{ __html:nameH1}}/>:<div dangerouslySetInnerHTML={{ __html:text}}/>):<FontAwesomeIcon className={iClass} icon={icon}/>
            }
            </p>
          )
        ):("")

      )

      const content = this.props.dataContent.edges[0].node.infoBar
      const langNo = this.props.lang

    return (

      <Container className={this.props.contClass} fluid>

        <Row className={this.props.rowClass} noGutters>
        {
          content.map((info, i)=>(
            <Col className={[info.class,this.props.colClass]} id={`field${i}`}>
            {
                  info.col.map(items=>(
                    <Data
                    href={items.item[2]}
                    icon={[items.item[0],items.item[1]]}
                    text={items.item[langNo]}
                    hClass={this.props.hrefClass}
                    iClass={this.props.iconClass}
                    pClass={this.props.paraClass}
                    nameH1={this.props.nameH1}
                    iter={i}
                    />
                  ))

              }
              </Col>
          ))
        }
        </Row>
      </Container>
    );
  }
 }

 export default props =>(
   <StaticQuery
     query={graphql`
       query {
         allJson(sort: {fields: infoBar}, limit: 1) {
           edges {
             node {
               infoBar {
                 class
                 col {
                   item
                 }
               }
             }
           }
         }
       }
     `}
     render = {({ allJson }) => <InfoBar dataContent={allJson} {...props}/>}
       />
 );
InfoBar.defaultProps={
  nameH1:""
}
